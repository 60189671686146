import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from "gatsby"
import Grid from '@material-ui/core/Grid';
// import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { navigate } from "gatsby"

import ClapButton from '../components/ClapButton'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        paddingBottom: '5%'
    },
    paper: {
        textAlign: 'center',
        color: theme.palette.text.secondary,
        boxShadow: '0 15px 15px 0 grey',
        transition: '0.3s',
    },
    media: {
        width: '100%',
        // paddingBottom: '30%',
        height: '200px',
        backgroundColor: 'white',
        marginBottom: 0
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        width: 50,
        height: 50,
        border: '2px solid #fff',
        margin: '-48px 32px 0 auto',
        '& > img': {
            margin: 0,
        },
        backgroundColor: '#43A6C6'
    },
    title: {
        fontSize: 20,
        color: '#2F75A9',
        cursor: 'pointer',
        textAlign: 'center',
    },
    date: {
        fontSize: 12,
        color: 'grey',
        float: 'left',
    },
    readTime: {
        float: 'right',
        fontSize: 12,
        color: 'grey'
    },
    cardContent: {
    },
    cardContentText: {
    },
    cardButton: {
        background: '#43A6C6',
        marginLeft: 'auto',
        alignItems: 'right',
        '&:hover': {
            backgroundColor: '#2E8BC0',
            color: '#FFF'
        }
    },
    cardActions: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    cardImage: {
        position: 'relative',
        display: 'inlineBlock',
    },
    cardImageIcon: {
        position: 'absolute',
        left: '78%',
        top: '4%',
        color: 'white',
        '&:hover': {
            backgroundColor: '#16D8E4',
        }
    },
    clapButton: {
        [theme.breakpoints.up('xs')]: {
            position: 'absolute',
            left: '77%',
            top: '6%',
        },
        [theme.breakpoints.up('sm')]: {
            position: 'absolute',
            left: '74%',
            top: '4%',
        },
        [theme.breakpoints.up('md')]: {
            position: 'absolute',
            left: '80%',
            top: '5%',
        },
        [theme.breakpoints.up('lg')]: {
            position: 'absolute',
            left: '%',
            top: '5%',
        },
    },
    tagData: {
        display: 'flex',
        flexFlow: 'row wrap',
        alignItems: 'center',
        justifyContent: 'center',
        paddingBottom: '2%',
        fontSize: 12,
        color: 'grey',
    },
    tagDataChip: {
        marginRight: '2.5%',
        marginLeft: '2.5%',
        cursor: 'pointer',
        whiteSpace: 'nowrap'
    },
    tag: {
        fontSize: 12,
        color: '#2E8BC0',
        marginBottom: '-10%'
    },
}));

const BlogCardHome = ({ posts }) => {
    const classes = useStyles();

    // function LinkButton(link, buttonText) {
    //     link = Object.values(link)
    //     buttonText = link[1]
    //     link = link[0]
    //     if (link.includes('http')) {
    //         return (
    //             <a style={{ boxShadow: `none` }} href={link} target='_blank' rel="noopener noreferrer">
    //                 <Button className={classes.cardButton}>
    //                     {buttonText}
    //                 </Button>
    //             </a>
    //         )
    //     } else {
    //         return (
    //             <Link style={{ boxShadow: `none` }} to={link}>
    //                 <Button className={classes.cardButton}>
    //                     {buttonText}
    //                 </Button>
    //             </Link>
    //         )
    //     }
    // }

    function LinkTitle(link, titleText) {
        link = Object.values(link)
        titleText = link[1]
        link = link[0]
        if (link.includes('http')) {
            return (
                <a style={{ boxShadow: `none` }} href={link} target='_blank' rel="noopener noreferrer">
                    <Typography className={classes.title}>
                        {titleText}
                    </Typography>
                </a>
            )
        } else {
            return (
                <Link style={{ boxShadow: `none` }} to={link}>
                    <Typography className={classes.title} >
                        {titleText}
                    </Typography>
                </Link>
            )
        }
    }

    function LinkDescription(link, titleDescription) {
        link = Object.values(link)
        titleDescription = link[1]
        link = link[0]
        if (link.includes('http')) {
            return (
                <a style={{ boxShadow: `none` }} href={link} target='_blank' rel="noopener noreferrer">
                    <Typography variant="body2" color="textSecondary" component="div" className={classes.cardContentText}>
                        <p>
                            {titleDescription}
                            &nbsp;.....
                        </p>
                    </Typography>
                </a>
            )
        } else {
            return (
                <Link style={{ boxShadow: `none` }} to={link}>
                    <Typography variant="body2" color="textSecondary" component="div" className={classes.cardContentText}>
                        <p>
                            {titleDescription}
                            &nbsp;.....
                        </p>
                    </Typography>
                </Link>
            )
        }
    }

    return (
        <div className={classes.root}>
            <Grid container spacing={5}>
                {posts.map(({ node }) => {
                    const title = node.frontmatter.title || node.fields.slug
                    const pic = node.frontmatter.image ? node.frontmatter.image.childImageSharp.resize : null
                    const link = node.frontmatter.link || node.fields.slug
                    // const buttonText = node.frontmatter.buttonText
                    const readingTime = node.fields.readingTime.text
                    const tags = node.frontmatter.tags
                    const slug = node.fields.slug.replace(/\//g, '')
                    const today = new Date();
                    const monthNames = ["January", "February", "March", "April", "May", "June",
                        "July", "August", "September", "October", "November", "December"
                    ];
                    
                    let neDate = node.frontmatter.date
                    neDate = node.frontmatter.date.replace(",", "")
                    neDate = neDate.split(" ")

                    const blogDay = neDate[1]
                    const blogMonth = neDate[0]
                    const blogYear = neDate[2]

                    const blogMonthIndex = monthNames.findIndex(
                        month => month === blogMonth
                    )

                    let finalBlogDate = new Date(blogYear, blogMonthIndex, blogDay)

                    if (finalBlogDate.getTime() <= new Date().getTime()) {
                        return (
                            <Grid item xs={12} sm={6} md={6} lg={4} key={node.fields.slug}>
                                <Card className={classes.paper}>
                                    <div className={classes.cardImage}>
                                        <CardMedia
                                            className={classes.media}
                                            image={pic.src}
                                            title={title}
                                        />
                                        {/* <IconButton aria-label="add to favorites" className={classes.cardImageIcon}>
                                            <FavoriteIcon />
                                        </IconButton> */}

                                        <div aria-label="add to favorites" className={classes.clapButton}>
                                            <ClapButton slug={slug} />
                                        </div>
                                    </div>
                                    <CardContent className={classes.cardContent}>
                                        <LinkTitle link={link} titleText={title} />
                                        <LinkDescription link={link} titleText={node.frontmatter.description || node.excerpt} />
                                        <div className={classes.tagData}>
                                            {tags.map((tag, i) => {
                                                if (tag === 'article' || tag === 'course') {
                                                    return []
                                                } else {
                                                    return [
                                                        <div
                                                            key={tag}
                                                            className={classes.tagDataChip}
                                                            variant="outlined"
                                                            onClick={event => { navigate(`/tag/${tag}`) }}
                                                        >
                                                            <Typography color="textSecondary" component="div" className={classes.tag}>
                                                                {tag}
                                                            </Typography>
                                                        </div>
                                                    ]
                                                }
                                            })}
                                        </div>
                                        <div>
                                            <Typography color="textSecondary" component="div" className={classes.date}>
                                                {node.frontmatter.date}
                                            </Typography>
                                            <Typography color="textSecondary" component="div" className={classes.readTime}>
                                                {readingTime}
                                            </Typography>
                                        </div>
                                    </CardContent>
                                    {/* <CardActions className={classes.cardActions}>
                                        <LinkButton link={link} buttonText={buttonText} />
                                    </CardActions> */}
                                </Card>
                            </Grid>
                        )
                    }
                })
                }
            </Grid>
        </div >
    );
}
export default BlogCardHome
